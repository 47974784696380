#footer #logo {
  width: 72px;
}

#footer .accordion {
  --bs-accordion-bg: var(--ilis-tertiary-3);
}

#footer .accordion-item {
  background-color: none;
  border: none;
  border-bottom: var(--ilis-tertiary-1) solid 2px;
}

#footer .accordion-button::after {
  background-image: var(--ico-dropdown);
}

#footer .accordion-button,
#footer .accordion-button .accordion-button:not(.collapsed),
#footer .accordion-body {
  color: var(--ilis-primary-4);
  background-color: var(--ilis-tertiary-3);
}

#footer .accordion-button::after {
  fill: var(--ilis-primary-4);
}

/** Padding en footer cuando esta en modo mobile por el nav*/
@media (max-width: 768px) {
  #footer #logo {
    width: 50px;
  }
  #footer {
    margin-bottom: 50px;
  }
}
