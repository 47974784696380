/** 
 * Estilos básicos para el Slider de Imágenes.
 */

/* Ajustes generales para el contenedor del slider. */
.product-image-slider .slick-slider {
  position: relative !important;
}

/* Estilos para los indicadores de paginación (miniaturas). */
.product-image-slider .slick-dots {
  position: static !important;
}

/* Personalización de cada miniatura de paginación. */
.product-image-slider .slick-dots li {
  padding: 3px;
  border-radius: 40px;
  width: 50px;
  height: 50px;
}

.product-image-slider .slick-dots li button:before {
  font-size: 12px;
}

.product-image-slider .slick-dots li.slick-active button:before {
  color: var(--ilis-primary-1) !important;
}

/* Estilo para la miniatura activa que muestra la imagen actual en el slider. */
.product-image-slider .slick-thumb .slick-active {
  background-color: var(--ilis-primary-1);
}

.product-image-slider .image-gallery-container,
.product-image-slider .magnify-container,
.product-image-slider .magnify-image-container {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

.product-image-slider .magnify-image-container img {
  width: 100% !important;
  height: 100% !important;
}

.product-image-slider .magnify-zoom {
  width: 100% !important;
  height: 100% !important;
  background-size: 150% !important;
  background-repeat: repeat;
}

/* Ajustes para miniaturas en pantallas pequeñas. */
@media screen and (max-width: 1320px) {
  .product-image-slider .slick-dots li {
    width: 40px;
    height: 40px;
  }
}

/* Ajustes para miniaturas en  mobile. */
@media screen and (max-width: 992px) {
  .product-image-slider .slick-dots li {
    width: 20px;
    height: 20px;
  }
}
