/**
 * Estilos CSS para los componentes dentro de los formularios de React.
 */

/** INICIO GENERALIDADES INPUT*/

#input-form input::placeholder,
#input-form textarea::placeholder {
  color: var(--ilis-tertiary-1);
}

/** Estilos para el input del formulario enfocado.*/
.form-control:focus,
.form-select:focus {
  border-width: 2px;
  border-color: var(--ilis-primary-1);
  outline: 0;
  box-shadow: none;
}

/** Estilos para el input del formulario en caso de error. */
.form-control-error {
  border-width: 2px !important;
  border-color: var(--ilis-complementary-2) !important;
  outline: 0;
  box-shadow: none;
}

/** Estilos para el desplegable de banderas en caso de error en el formulario. */
.form-control-error ~ .flag-dropdown {
  background-color: var(--ilis-complementary-2) !important;
}

/** Estilos para el input del formulario en caso de error enfocado. */
.form-control-error:focus {
  border-color: var(--ilis-complementary-2) !important;
}

/** Estilos para el input interno del formulario cuando está seleccionado automáticamente. */
input:-internal-autofill-selected {
  background-color: #fff !important;
}

/** FIN GENERALIDADES INPUT*/

/** INICIO VALIDACIONES LISTA */

/** Estilos para el marcador de lista de texto de error.*/
li.text-complementary-2::marker {
  content: 'x   ';
  font-size: 1.2em;
}

/** Estilos para el marcador de lista de texto válido. */
li.text-complementary-1::marker {
  content: '✓  ';
  font-size: 1.2em;
}

/** FIN VALIDACIONES LISTA */

/** INICIO ICONOS EN INPUTS

/**Estilos para el primer icono del input. */
.first-input-icon {
  position: absolute;
  top: 6px;
  left: 15px;
  height: 20px;
  display: block;
}

/** Estilos para el ultimo icono del input.*/
.last-input-icon {
  position: absolute;
  top: 8px;
  right: 15px;
  height: 20px;
  display: block;
}

.form-control:focus ~ #first-icon svg.path-fill path,
.form-control:focus ~ #first-icon li svg.path-fill path {
  fill: var(--ilis-primary-1) !important;
}

.form-control:focus ~ #first-icon svg.path-stroke path,
.form-control:focus ~ #first-icon li svg.path-stroke path {
  stroke: var(--ilis-primary-1) !important;
}

/** FIN ICONOS EN INPUT */

/** INICIO INPUT TIPO SWITCH & CHECK */

/** Estilos para el input de tipo switch en el formulario.*/
.form-switch .form-check-input {
  padding: 12px 24px 12px 24px;
}

/** Estilos para el input del formulario enfocado.  */
.form-check-input:focus {
  border-color: var(--ilis-tertiary-1);
  outline: 0;
  box-shadow: none;
}

/** Estilos para el input del formulario marcado. */
.form-check-input:checked {
  background-color: var(--ilis-complementary-1);
  border-color: var(--ilis-complementary-1);
}

/** Estilos para el input de tipo switch enfocado en el formulario. */
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: var(--ico-circle-gray);
}

/** Estilos para el input de tipo switch marcado en el formulario. */
.form-switch .form-check-input:checked {
  --bs-form-switch-bg: var(--ico-circle-white);
}

/** FIN INPUT TIPO SWITCH & CHECK */

/** INICIO FORMULARIO SELECT */

.form-select {
  --bs-form-select-bg-img: var(--ico-dropdown-select) !important;
  background-size: 24px !important;
}

/** FIN FORMULARIO SELECT */

/** INICIO  FORMULARIO DE MODALES */

/** Estructura del Modal de Login  y Registro  */
.bg-ilis-modal {
  background-image: var(--img-background);
  background-repeat: no-repeat;
  background-size: cover;
}

/** FIN  FORMULARIO DE MODALES */

#modal-mobile-logo {
  width: 114px;
}

/** Estructura del Formulario */

.translate-middle {
  transform: translate(-50%, -45%) !important;
}

/** Adaptación a Tablets*/
@media (min-width: 576px) {
  /** Estructura del Modal    */

  .logo-full-modal {
    width: 100px;
  }

  .translate-middle {
    transform: translate(-50%, -20%) !important;
  }

  #login-modal .modal-body,
  #sign-up-modal .modal-body {
    background-color: var(--ilis-secondary-4);
    padding: 0;
  }

  .start-10 {
    left: 10% !important;
  }
  .top-10 {
    top: 10% !important;
  }

  #login > .rounded-top-5,
  #sign-up > .rounded-top-5 {
    border-top-left-radius: var(--bs-border-radius-lg) !important;
    border-top-right-radius: var(--bs-border-radius-lg) !important;
    border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  }
}

/** Adaptación a Desktop */
@media (min-width: 768px) {
  .translate-middle {
    transform: translate(-50%, -50%) !important;
  }
}

/** Adaptación a Landscapes*/
@media (min-width: 768px) and (max-width: 992px) {
  /** Estructura del Modal
    */
  .translate-middle {
    transform: translate(-50%, -30%) !important;
  }
}
