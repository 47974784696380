/** Cabecera del Form Wizard*/
.react-form-wizard .wizard-header {
  display: none;
}

/** Sección de pasos del Form Wizard */

.react-form-wizard .wizard-nav-pills>li>a,
.react-form-wizard .wizard-nav-pills>li>a:hover,
.react-form-wizard .wizard-nav-pills>li>a:focus {
  display: flex !important;
}

/** Circulos del Form Wizard*/

a.active .wizard-icon-container {
  background-color: var(--ilis-primary-3) !important;
}

.wizard-icon-circle {
  width: 32px !important;
  height: 32px !important;
}

.react-form-wizard .wizard-icon-circle {
  border-color: var(--ilis-secondary-5) !important;
  background-color: var(--ilis-secondary-5) !important;
}

.fw-white .react-form-wizard .wizard-icon-circle {
  border-color: var(--ilis-primary-4) !important;
  background-color: var(--ilis-primary-4) !important;
}

a:not(.active)>.wizard-icon-circle.checked {
  border-color: var(--ilis-primary-3) !important;
  background-color: var(--ilis-primary-3) !important;
}

/** Barra del Form Wizard*/
.wizard-progress-bar {
  background-color: var(--ilis-primary-3) !important;
}

.react-form-wizard.md .wizard-navigation .wizard-progress-with-circle {
  top: 20px !important;
  background-color: var(--ilis-secondary-5) !important;
}

.fw-white .react-form-wizard.md .wizard-navigation .wizard-progress-with-circle {
  top: 20px !important;
  background-color: var(--ilis-primary-4) !important;
}

/** Titulos del Form Wizard*/

.stepTitle {
  font-size: 12px !important;
  font-weight: 500;
  margin: 15px 0 0 5px !important;
}

.stepTitle:not(.active) {
  color: var(--ilis-secondary-3) !important;
}

.stepTitle.active {
  color: var(--ilis-primary-3) !important;
  font-weight: 500 !important;
}

.react-form-wizard .wizard-nav-pills>li>a {
  color: var(--ilis-primary-3) !important;
}

/** Icono del Form Wizard*/
a:not(.active) .wizard-icon span {
  color: var(--ilis-primary-3) !important;
}

a:not(.active) .checked span {
  display: none;
}

a:not(.active) .checked .wizard-icon-container {
  display: none;
}

a:not(.active) .checked::after {
  content: '✓ ';
  align-self: center;
  color: var(--ilis-primary-4) !important;
}

.wizard-icon span {
  font-weight: bold !important;
}