/**
 * Estilos para la barra de paginación.
 */
#pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 30px 0;
}

/**
 * Estilos para cada ítem de la paginación.
 */
#pagination li {
  height: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0 5px;
  border-radius: 18px;
}

/**
 * Estilos para el enlace dentro de cada ítem de la paginación.
 */
#pagination li a {
  border-radius: 18px;
  width: 100%;
  text-align: center;
  border: 1px solid transparent !important;
}

/**
 * Estilos para el ítem activo de la paginación.
 */
#pagination li.btn-pagination.active,
#pagination li.btn-pagination:focus,
#pagination li.btn-pagination:hover {
  background-color: var(--ilis-primary-3);
  color: var(--ilis-primary-4);
}

/**
 * Estilos para el ítem deshabilitado de la paginación.
 */
#pagination li.disabled {
  background: none;
  color: var(--ilis-tertiary-1);
}

/**
 * Estilos para los botones de navegación de la paginación (anterior, siguiente).
 */
#pagination li.btn-pagination-prev,
#pagination li.btn-pagination-next {
  background-color: var(--ilis-primary-4);
  color: var(--ilis-tertiary-1);
}

/**
 * Estilos al pasar el cursor sobre los botones de navegación de la paginación (anterior, siguiente).
 */
#pagination li.btn-pagination-prev:hover,
#pagination li.btn-pagination-next:hover {
  box-shadow: var(--shadow);
}

/**
 * Estilos al pasar el cursor sobre los botones de navegación de la paginación (anterior, siguiente).
*/
#pagination li.btn-pagination-prev.disabled:hover,
#pagination li.btn-pagination-next.disabled:hover {
  box-shadow: none;
}

/**
 * Estilos para los botones de navegación deshabilitados de la paginación (anterior, siguiente).
 */
#pagination li.btn-pagination-prev.disabled,
#pagination li.btn-pagination-next.disabled {
  background-color: var(--ilis-primary-4);
  color: var(--ilis-tertiary-5);
  padding: 0px 8px;
  box-shadow: none !important;
}

/**
 * Estilos para los enlaces dentro de los botones de navegación de la paginación (anterior, siguiente).
*/
#pagination li.btn-pagination-prev a,
#pagination li.btn-pagination-next a {
  border: 1px solid !important;
}
