.accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: transparent;
}

.accordion-item {
  background-color: transparent;
  border: none;
}

.accordion-button,
.accordion-button:not(.collapsed),
.accordion-body {
  background-color: transparent;
  color: var(--ilis-secondary-1);
}

.accordion-item:last-of-type,
.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-item:first-of-type .accordion-button {
  border-radius: 0 !important;
}

.drop-down-button .accordion-button::after,
.drop-down-button .accordion-button:not(.collapsed)::after {
  background-image: var(--ico-dropdown);
}

.without-drop-down-button .accordion-button::after,
.without-drop-down-button .accordion-button:not(.collapsed)::after {
  background-image: none !important;
}

.accordion-button::after {
  fill: var(--ilis-tertiary-1);
}

.accordion-button:focus {
  box-shadow: none;
}
