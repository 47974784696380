/**
* Estilos para el componente RangeSlider.
*/
.range-slider {
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    height: 4px;
    background: var(--ilis-tertiary-5);
}


/**
* Estilos para el control deslizante del componente RangeSlider.
*/
.range-slider .range-slider__thumb {
    position: absolute;
    z-index: 3;
    top: 50%;
    width: 22px;
    height: 22px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: solid 1px var(--ilis-primary-1);
    background: var(--ilis-primary-4);
}

/**
* Estilos para el control deslizante del componente RangeSlider cuando está enfocado.
*/
.range-slider .range-slider__thumb:focus-visible {
    outline: 0;
}

/**
* Estilos para el rango del componente RangeSlider.
*/
.range-slider .range-slider__range {
    position: absolute;
    z-index: 1;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
    height: 100%;
    background: var(--ilis-primary-1);
}

/**
* Estilos para el elemento input[type='range'] dentro del componente RangeSlider.
*/
.range-slider input[type='range'] {
    appearance: none;
    -webkit-appearance: none;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
}

/**
* Estilos para el pulgar del control deslizante en webkit (Chrome, Safari).
*/
.range-slider input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
}

/**
* Estilos para el pulgar del control deslizante en Firefox (Mozilla).
*/
.range-slider input[type='range']::-moz-range-thumb {
    width: 0;
    height: 0;
    border: 0;
}

/**
* Estilos para el elemento input[type='range'] cuando está enfocado.
*/
.range-slider input[type='range']:focus {
    outline: 0;
}
