/**
 * Estilos para el despliegue en forma de grid o lista.
 */

/**
 * Estilos para los elementos de la lista.
 */
.list-group-item {
  margin: 1rem;
}

/**
 * Estilos al pasar el cursor sobre un elemento de la lista.
 */
.list-group-item:hover {
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
}
