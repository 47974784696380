tbody tr:hover:not(.active, .disabled) {
  background: var(--ilis-primary-5) !important;
}

tbody tr.disabled {
  filter: grayscale();
}

tr:not(.active) .icon-selection {
  visibility: hidden;
}

tr:hover:not(.active, .disabled) .icon-selection {
  visibility: unset;
}

tr.active {
  background: var(--ilis-secondary-4) !important;
}

tr.active .icon-selection svg path {
  fill: var(--ilis-primary-1) !important;
}

ul.attribute-list {
  overflow-y: auto;
  max-height: 120px;
}