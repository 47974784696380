/** header.css
* 
* Este CSS incluye todo el estilo de los headers desktop y mobile ademas de los componentes internos del mismo. 
*
* Index 
* - Header Mobile y Componentes
* - Header Desktop y Componentes
* 
*/

.top-submenu {
  top: var(--submenu-desktop-position) !important;
}

@media screen and (max-width: 450px) {
  .top-submenu {
    top: var(--submenu-mobile-position) !important;
  }
}

#header .border-primary-1 {
  border: 1px solid var(--ilis-primary-1);
  padding: 4px 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
}

/** Header Desktop
*/

#header-desktop .border-white:hover path {
  fill: var(--ilis-tertiary-4) !important;
}

#header-desktop .border-primary-1:hover,
#header-desktop .border-white:hover {
  border: 1px solid var(--ilis-tertiary-4) !important;
  color: var(--ilis-tertiary-4) !important;
}

#header-desktop .border-white {
  border: 1px solid white;
  padding: 7px 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

#header-desktop .border-white:hover {
  background-color: rgb(255 255 255 / 20%);
}

#submenu {
  max-height: 90vh;
  overflow-y: auto;
}
