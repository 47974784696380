/**
 * Este CSS incluye todo el estilo del preview del producto en modo escritorio y mobile.
 */

/* Estilo para los iconos de tipos de envío */
.shipping-type svg {
  height: 22px;
}

/* Estilo para el ícono de favorito en el modo de vista previa del producto */
.product-preview .favorite-icon svg {
  width: 100%;
}

.product-title {
  min-height: 48px;
}

/** Entorno Desktop */
@media (min-width: 768px) {
  /* Estilo para el ícono de favorito al pasar el cursor por encima */
  .product-preview:hover .favorite-icon {
    visibility: visible !important;
  }

  /* Estilo para el efecto de sombra al pasar el cursor por encima del preview del producto */
  .product-preview:hover {
    box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.25) !important;
  }
}
