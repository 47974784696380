.tag {
  border-radius: var(--bs-border-radius-xl) !important;
  background-color: var(--ilis-tertiary-5) !important;
  padding: 5px 0;
}

.btn-tag {
  width: 25px !important;
  height: 25px !important;
  padding: 0px 5px;
  border-radius: 20px !important;
  transition: background-color 0.3s ease-in-out !important;
}

.btn-tag:not(.disabled, :disabled):hover,
.btn-tag:not(.disabled, :disabled):active,
.btn-tag:not(.disabled, :disabled):focus {
  background-color: var(--ilis-secondary-5) !important;
  box-shadow: 0 0.1rem 0.4rem rgba(var(--bs-body-color-rgb), .6) !important;
  cursor: pointer !important;
}