.carousel .slick-dots {
  position: relative !important;
  bottom: -15px !important;
}

.carousel-category .slick-list {
  height: 60px !important;
}

.carousel .slick-dots li button:before {
  opacity: 1 !important;
  font-size: 8px !important;
  color: var(--ilis-tertiary-5) !important;
}

.carousel .slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: var(--ilis-secondary-3) !important;
}

.full-centered-carousel .btn-slider {
  margin: 0 !important;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out !important;
}

.full-centered-carousel .btn-slider:hover {
  opacity: 1;
}

.full-centered-carousel .slick-slider {
  padding-bottom: 1%;
}

.padding-arrows-carousel .arrow-carousel .slick-slider {
  padding: 0 3.5rem;
}
