/** Propiedades del modal de guia de especificaciones */

.slide-cart-modal-dialog {
  position: inherit;
  right: 0;
  bottom: 0;
  margin: 0;
}

.modal-backdrop {
  opacity: 0.7 !important;
}

/** Adaptación a entorno Desktop */
@media (min-width: 768px) {
  .slide-cart-modal-dialog {
    width: 650px;
  }
}

/** Adaptación a entorno mobile */
@media (max-width: 767px) {
  .slide-cart-modal-dialog {
    height: 82%;
    bottom: 52px;
  }
}

@media screen and (max-width: 320px) {
  .p-smaller {
    font-size: 9px !important;
  }
  .p-title-bold {
    font-size: 9px !important;
  }

  .h5 {
    font-size: 14px !important;
  }
  .slide-cart-modal-dialog {
    height: 92%;
    bottom: 7%;
  }
}
@media screen and (max-width: 375px) {
  .p-smaller {
    font-size: 14px !important;
  }
}
