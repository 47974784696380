/** Propiedades del modal de guia de especificaciones */

.slide-modal-dialog {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  border-radius: 0 !important;
}

.modal-backdrop {
  opacity: 0.7 !important;
}

/** Adaptación a entorno Desktop */
@media (min-width: 768px) {
  .slide-modal-dialog {
    width: 960px;
  }
}
