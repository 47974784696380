.status-order-tab .line {
  background-color: var(--ilis-tertiary-1);
  padding: 3px 0;
}

.status-order-tab .icon {
  position: absolute;
  background-color: var(--ilis-tertiary-1);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.status-order-text span {
  color: var(--ilis-tertiary-1);
}

.status-order-tab.active .line {
  background-color: var(--ilis-primary-3);
}

.status-order-tab.active .icon {
  background-color: var(--ilis-primary-3);
}

.status-order-text.active span {
  color: var(--ilis-primary-3);
}
