/** NavUser.css 
* 
* Este CSS incluye todo el estilo del Nav de usuario que se muestra en las modales mobile y la sección izquierda de desktop. 
*
* Index 
* - Colores de SVG y Textos 
* - Estilos de las listas  
* - Estilos de los parrafos de listas 
* - Efectos ::hover de listas 
*/

/** Estilos de las listas
*/
#nav-user li {
    padding: 0 3% 0 3%;
}

/** Estilos de los parrafos de listas
*/
#management-title li p {
    padding-left: 10px;
}

/** Tamaño a los parrafos pequeños */
#nav-user small {
    font-size: 11px;
}

/** Oculta el texto corto de la lista de administración
*/
.nav-desktop #management-list li small {
    display: none;
}

/*
#management-list li:hover, #client-list li:hover, #close-session li:hover {
    background-color: var(--ilis-secondary-4);
}
*/
#management-title li:hover {
    background-color: #fff;
}

/** Propiedades del modal
*/
#modal-nav-user .modal-content {
    border-radius: 0;
}

#modal-nav-user.left {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    border-radius: 0 !important;
}

#modal-nav-user #management-list ul li#bar {
    border-bottom: 2px solid var(--ilis-secondary-5);
    padding-bottom: 20px !important;
}

#modal-nav-user #management-list ul li#bar {
    background-color: #fff;
}

/** Adaptación a entorno Desktop*/
@media (min-width: 768px) {

    #modal-nav-user.left {
        width: 320px;

    }
}