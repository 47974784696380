/** alertModal.css 
* 
* Este CSS incluye todo el estilo de los modales  que se muestran al generarse una alerta. 
*
*/

/** Define la estructura interna del modal*/
.alert-primary{
    background-color: var(--ilis-primary-5);
    border: none;
}
#alert-modal p{font-size: 20px;}
.btn-close:focus {box-shadow: none !important}

/** Define todas las propiedades de las alertas de éxito */
.text-success, .text-success path, .border-success {
    color:var(--ilis-complementary-1) !important;
    fill:var(--ilis-complementary-1) !important;
    border-top-color:var(--ilis-complementary-1) !important;
    border-bottom-color:#fff !important;
}

/** Define todas las propiedades de las alertas Warning*/
.text-warning, .text-warning path, .border-warning {
    color: var(--ilis-complementary-4) !important;
    fill: none;
    stroke: var(--ilis-complementary-4) !important;
    stroke-width: 2px;
    border-color: var(--ilis-complementary-4) !important;
    border-bottom-color:#fff !important;
}

/** Define todas las propiedades de las alertas de información */
.text-info, .text-info path, .border-info {
    color: var(--ilis-complementary-3) !important;
    fill: var(--ilis-complementary-3) !important;
    border-color: var(--ilis-complementary-3) !important;
    border-bottom-color:#fff !important;
}

/** Define todas las propiedades de las alertas de Error */
.text-danger, .text-danger path, .border-danger {
    color: var(--ilis-complementary-2) !important;
    fill: var(--ilis-complementary-2) !important;
    border-color: var(--ilis-complementary-2) !important;
    border-bottom-color:#fff !important;
}