.styles-module_item-container__a8zaY{
    display: flex;
    align-items: center !important;
}
.thumbnail-img{
    width: 120px;
    height: 120px;
    background-size: cover;
}


.primary-img{
    translate: -50%;
    padding: 4px 8px;
}

