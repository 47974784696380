/** navMobile.css 
* 
* Este CSS incluye todo el estilo del Nav principal que solo se vera en mobile. 
*
*/

/** Colores de SVG y Textos
*/
.nav-mobile li {
  border-bottom: 4px solid var(--ilis-primary-5);
}

.nav-mobile li > a.active {
  border-bottom: 4px solid var(--ilis-primary-2);
}

.nav-mobile .active svg.path-fill path,
.nav-mobile .active li svg.path-fill path {
  fill: var(--ilis-primary-2) !important;
}

.nav-mobile .active svg.path-stroke path,
.nav-mobile .active li svg.path-stroke path {
  stroke: var(--ilis-primary-2) !important;
}
