/** UserOptions.css 
* 
* Este CSS incluye todo el estilo del Nav de usuario que se muestra en las modales mobile y la sección izquierda de desktop. 
*
* Index 
* - Estilos de Titulo Principal
* - Estilos del Modal
* - Estilos del Calendario
* - Adaptación a Entorno Desktop
*
*/

/** Estilo de titulo Principal
*/
#user-title li p {
  font-size: 20px;
  font-weight: bold;
}

#user-title svg.path-fill path,
#user-title li svg.path-fill path {
  fill: var(--ilis-primary-3) !important;
}

#user-title svg.path-stroke path,
#user-title li svg.path-stroke path {
  stroke: var(--ilis-primary-3) !important;
}

#modal-user-info .min-vh {
  min-height: 82vh !important;
}
#modal-user-info .mt-20 {
  margin-top: 18vh;
}

/** Estilos del Calendario
*/

/** Efectos que se aplican al componente calendario dentro del datapicker libreria usa
*/

.react-datepicker {
  font-size: 1rem !important;
  border-radius: 2rem !important;
  border: none !important;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
}

.react-datepicker select {
  text-align: center;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--ilis-primary-1) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  text-transform: uppercase;
  margin: 10px 0 10px 0 !important;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  width: 105px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.62rem !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-radius: 2rem !important;
  background-color: #ffffff !important;
  border-bottom: none !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

#input-list .border-bottom {
  transition: border-bottom 0.2s ease-in-out;
}

#input-list .border-bottom:hover {
  border-bottom: solid 2px var(--ilis-primary-1) !important;
}

#input-list li:hover svg.path-fill path,
#input-list li:hover li svg.path-fill path {
  fill: var(--ilis-primary-1) !important;
}

#input-list li:hover svg.path-stroke path,
#input-list li:hover li svg.path-stroke path {
  stroke: var(--ilis-primary-1) !important;
}

/** Adaptación a entorno Desktop*/
@media (min-width: 768px) {
  #content-user #close-session {
    display: none;
  }
  .content-info #client-list #account.btn-list-link-icon-light-small {
    background-color: var(--ilis-secondary-4) !important;
    border-radius: var(--border-radius);
  }

  .content-password #client-list #password.btn-list-link-icon-light-small {
    background-color: var(--ilis-secondary-4) !important;
    border-radius: var(--border-radius);
  }

  #modal-user-info .min-vh {
    min-height: 65vh !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0.2rem !important;
  }
}
