[class*='css-'][class$='-control'] {
  border-color: var(--ilis-tertiary-5) !important;
  box-shadow: none !important;
}

[class*='css-'][class$='-control'] > div {
  display: flex !important;
  align-items: center;
  min-height: 80px;
  padding: 0 !important;
}

[class*='css-'][class$='-control'] > div input {
  position: absolute;
}

[class*='css-'][class$='-menu'] .active .dropdown-item,
[class*='css-'][class$='-menu'] .dropdown-item:hover {
  background-color: var(--ilis-secondary-4) !important;
}

[class*='css-'][class$='-indicatorContainer'] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}

.select-status-pill {
  display: none !important;
}

[class*='css-'][class$='-menu'] .select-status-pill {
  display: block !important;
}

.dropdown-border {
  min-height: 80px;
  border: 1px solid var(--ilis-tertiary-5) !important;
  background: var(--ilis-primary-4) !important;
  color: var(--ilis-secondary-2) !important;
  border-radius: var(--border-radius) !important;
}

[class*='css-'][class$='-indicatorSeparator'],
[class*='css-'][class$='-indicatorContainer'] svg,
[class*='css-'][class$='-menu'] .select-icon,
.dropdown-border .select-icon {
  display: none !important;
}
